.music {
  background-image: url("../../images/BG2.jpg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  width: 100%;
  padding: 250px 50px;
  background-position: center;
}
.soundcloud {
  background-color: white;
  padding: 20px;
  margin: 10px;
}

.spotify {
  background-color: white;
  padding: 20px;
  margin: 10px;
}

.linkContainer {
  font-size: 10px;
  color: #cccccc;
  line-break: anywhere;
  word-break: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
    Garuda, Verdana, Tahoma, sans-serif;
  font-weight: 100;
}

.link {
  color: #cccccc;
  text-decoration: none;
}

.playlist {
  color: #cccccc;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  /* For desktop: */

  .header {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }

  .releases {
    padding: auto;
    width: 100%;
    height: auto;
  }

  .spotify {
    width: 100%;
    padding: 0px;
    margin: 0px;
  }
  .soundcloud {
    padding: 10px 0px 0px 0px;
    margin: 30px 0px 0px 0px;
  }
  .music {
    padding-top: 100px;
  }
}
