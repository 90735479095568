.home {
  background-image: url("../../images/BG1.jpg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  width: 100%;
  padding: 100px 50px;
  background-position: center;
}

.showImage{
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.videoContainer {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

/* .videoContainer iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
} */

@media only screen and (max-width: 768px) {
/*   .videoContainer {
    padding: 40% 0px;
    margin: 70px -30px;
  } */

  .home {
    padding: 200px 50px;
  }
  
.showImage {
  height:100%;
  width:100%;
}
  
} 
