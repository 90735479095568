.footer {
  background-color: black;
  color: white;
  padding: 30px 0px 10px 0px;
}

.footer a{
  color:rgb(45, 132, 255);
}

.management {
  margin-bottom: 40px !important;
}

.mgmt {
  margin-bottom: 23px;
}

@media only screen and (max-width: 768px) {
  .footer {
    padding: 30px 0px 30px 0px;
  }
  .mgmt {
    margin-bottom: 0px;
  }
}
