.header {
  height: 10%;
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.75);
}

.collapseMenu {
  height: auto;
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.75);
}

.nav {
  color: black !important;
  font-weight: bold;
  font-family: "Proxima N W01 Bold", Helvetica, Arial, sans-serif;
  font-size: 12px;
  margin-left: 10px;
}

.nav:hover {
  font-weight: bold;
  text-decoration: none;
}

#merch {
  margin-top:14px;
}

.logo {
  height: 40px;
}

.socials {
  margin-right: 5%;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  width: 20px;
}

.socials:hover {
  opacity: 0.5;
  cursor: pointer;
}

.listen {
  font-size: 14px;
}

.listen:hover {
  opacity: 0.5;
  cursor: pointer;
}

.musicLinks {
  background-color: black !important;
  color: white !important;
  padding: 5px 30px !important;
  font-size: 14px !important;
}

.musicLinks:hover {
  border: none;
  background-color: white !important;
  color: black !important;
}

.button {
  width: 20%;
  font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, Verdana,
    sans-serif;
  font-size: 14px;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  letter-spacing: 0.03em;
  color: black;
  background-color: transparent;
  box-sizing: border-box;
  height: 32px;
  line-height: 32px;
  padding: 0px;
  display: inline-block;
  margin: 0;
  transition: all 0.23s ease-in-out 0s;
  text-align: right;
}

.button:hover {
  color: #ff0d28;
  cursor: pointer;
}

.input {
  width: 80px;
  font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, Verdana,
    sans-serif;
  font-size: 14px;
  border: 0px solid #abb0b2;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: transparent;
  box-sizing: border-box;
  height: 32px;
  padding: 0px 0.4em;
  display: inline-block;
  margin: 0;
  width: 100%;
  vertical-align: top;
}

.input:focus {
  outline: none !important;
}

.button:focus {
  outline: none !important;
}

.form {
  border-bottom: solid 1px black;
  width: 360px;
  margin: 10px -20px 0px 30px;
}

.subscribe {
  background-color: black;
  width: 200px;
  color: white;
  text-align: center;
  padding: 5px 10px;
  margin: 7px 30px;
}

.subscribe:hover {
  cursor: pointer;
  background-color: #ff0d28;
}

.socialContainer {
  text-align: right;
  min-width: 0 !important;
}

.errorMessage {
  margin-left: 30px;
}

@media only screen and (max-width: 768px) {
  .nav {
    height: auto;
    margin-left: 0px;
    margin-top: 0px;
  }

  .header {
    height: auto;
    margin-top: 10%;
  }

  .logo {
    content: url("../../images/favicon.png");
    height: 40px;
  }

  .form {
    margin: 0px 0px 10px 0px;
    width: 300px;
  }

  .socialContainer {
    text-align: center;
    padding: 0px !important;
  }

  .subscribe {
    margin: 0px;
  }

  .errorMessage {
    white-space: normal;
    margin-left: 0px;
  }

  .button {
    margin-left: 10px;
    width: 90px;
    padding: 0px;
  }
}

@media screen and (min-width: 768px) {
  .logo {
    content: url("../../images/logo.png");
  }
}

@media (min-width: 992px) {
  .logo {
    content: url("../../images/logo.png");
  }
}

@media (min-width: 1200px) {
  .logo {
    content: url("../../images/logo.png");
  }
}