.videos {
  background-image: url("../../images/BG3.jpg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  width: 100%;
  padding: 100px 50px;
  background-position: center;
}

.title {
  margin-top: 30px;
  color: white;
}

@media only screen and (max-width: 400px) {
  .videos {
    background-image: url("../../images/BG3.jpg");
  }
  .video {
    width: 300px;
    height: 169px;
    padding: 0px;
    margin: 0px;
  }
  .title {
    margin-top: 15px;
    color: white;
  }
}
