.body {
  font-family: Gotham, "Helvetica Neue", Helvetica, Arial, "sans-serif";
}

.nav-link:hover {
  color: #a72232 !important;
}

.dropdown-menu {
  background-color: rgba(0, 0, 0, 0) !important;
  background-clip: none !important;
  border: 0 !important;
  box-shadow: none;
  border-radius: 0.25rem;
  color: black !important;
}

.navbar-toggler {
  width: 50px;
  height: 50px;
}


.navbar-toggler-icon {
  margin: 0px;
}

@media only screen and (max-width: 768px) {
  .Header_listen__1CiBe {
    font-size: 14px;
    color: black;
  }

  .glyphicon {
    padding: 0px;
    margin: 0px;
    color: black;
  }

  .navbar-brand {
    padding: 0px;
  }
}