.tour {
  background-image: url("../../images/BG4.jpg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  width: 100%;
  padding: 70px 50px;
  background-position: center;
}

.showsContainer {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.bandsintown {
  margin: 130px 0px;
  background-color: #fff;
  opacity: 0.8;
}

@media only screen and (max-width: 768px) {
  .bandsintown {
    margin: 0px;
  }
}
